import React from 'react';
import Api from 'api/Api';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import PasswordField from 'components/interface/PasswordField/PasswordField';
import { Label } from 'components/interface/textField/TextField';
import ReactGA from 'react-ga';
import Button from 'components/interface/Button/Button';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PasswordRequirementsChecker from 'components/interface/PasswordField/PasswordRequirementsChecker';
import { isValidPassword } from 'utils/utils_legacy';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import Wrapper, { WelcomeText } from '../Wrapper';

const useStyles = makeStyles<void, 'loading' | 'hidden'>()(
    (theme, _params, classes) => ({
        body: {
            maxWidth: '350px',
            width: '100%',
            flexShrink: 0,
            opacity: 1,
            transition: 'opacity 2.0s ease-in-out',
            [`&.${classes.loading}`]: {
                transition: 'opacity 1.0s ease-in-out',
                opacity: 0,
            },
        },
        textFieldBase: {
            width: '100%',
            maxWidth: '350px',
            flexShrink: 0,
            marginTop: theme.spacing(0.5),
        },
        textField: {
            maxWidth: '350px',
        },
        textFieldRoot: {
            height: '48px',
        },
        showPasswordIcon: {
            color: theme.palette.primaryDark.main,
        },
        loginButtonWrapper: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.spacing(4),
        },
        loginButton: {
            width: '50%',
            height: '40px',
            opacity: 1,
            transition: 'opacity 1.0s ease-in-out',
            [`&.${classes.hidden}`]: {
                opacity: 0,
            },
        },
        forgotPasswordButtonWrapper: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '50%',
            textDecoration: 'underline',
            color: theme.palette.primaryDark.main,
            userSelect: 'none',
        },
        inputWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
            paddingBottom: theme.spacing(2),
        },
        welcomeText: {
            userSelect: 'none',
            color: theme.palette.primaryDark.main,
            paddingBottom: theme.spacing(4),
        },
        welcomeTextTitle: {
            paddingBottom: theme.spacing(1),
        },
        label: {
            'color': theme.palette.primaryDark.main,
            '& p': {
                fontWeight: 'bold',
            },
        },
        successText: {
            userSelect: 'none',
            color: theme.palette.primaryDark.main,
            paddingTop: theme.spacing(2),
        },
        desktop: {},
        loading: {},
        hidden: {},
    })
);

interface Props {
    isMobile: boolean;
}

export default function ChangePasswordComponent({ isMobile }: Props) {
    const { classes, cx } = useStyles();
    const location = useLocation();
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [textFieldFocus, setTextFieldFocus] = React.useState(false);

    const pushNotification = useNotificationStore(state => state.push);

    const changePasswordWithRecoveryToken = async (token: string) => {
        setLoading(true);
        try {
            const response = await Api.user.changePasswordWithRecoveryToken(
                password,
                token
            );
            const { status } = response;
            if (status === 200) {
                setSuccess(true);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att begära nytt lösenord, försök igen',
                'forgot-password-fail',
                1
            );
        }
        setLoading(false);
    };

    const handleChangePassword = () => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (checkPasswordFields(token)) {
            ReactGA.event({
                category: 'ChangePassword',
                action: 'Submit',
            });
            changePasswordWithRecoveryToken(token as string);
        }
    };

    const checkPasswordFields = (token: string | null) => {
        if (!isValidPassword(password)) {
            pushNotification(
                NotificationTypes.error,
                'Fyll i fälten korrekt, och försök igen.',
                'update-profile-fail',
                1
            );
            return false;
        } else if (!token) {
            pushNotification(
                NotificationTypes.error,
                'Länken är inte giltig, begär en ny.',
                'update-profile-fail',
                1
            );
            return false;
        } else {
            return true;
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleChangePassword();
        }
    };

    const loginButtons = () => {
        return (
            <div className={classes.loginButtonWrapper}>
                <div className={classes.forgotPasswordButtonWrapper}>
                    <NavLink exact to={`/logga-in`}>
                        <Typography
                            variant='body2'
                            color='inherit'
                            onClick={() => {
                                ReactGA.event({
                                    category: 'ChangePassword',
                                    action: 'Go to login',
                                });
                            }}>
                            <b>Tillbaka till login</b>
                        </Typography>
                    </NavLink>
                </div>
                <Button
                    color='primaryDark'
                    variant='contained'
                    onClick={handleChangePassword}
                    className={cx(classes.loginButton, {
                        [classes.hidden]: success,
                    })}
                    disabled={success || loading}
                    disableRipple
                    disableElevation>
                    <Typography variant='body1' color='inherit'>
                        <b>Ändra lösenord</b>
                    </Typography>
                </Button>
            </div>
        );
    };

    const textFields = () => {
        return (
            <>
                <div className={classes.inputWrapper}>
                    <div className={classes.label}>
                        <Label text='Nytt lösenord' inheritColor />
                    </div>
                    <PasswordRequirementsChecker
                        password={password}
                        tooltip
                        placement={isMobile ? 'top' : 'right'}
                        open={textFieldFocus}>
                        <PasswordField
                            id='password'
                            autoComplete='new-password'
                            classes={{
                                base: classes.textFieldBase,
                                field: classes.textField,
                            }}
                            InputProps={{
                                classes: { root: classes.textFieldRoot },
                            }}
                            iconClassName={classes.showPasswordIcon}
                            value={password || ''}
                            onChange={value => setPassword(value)}
                            onKeyPress={handleKeyPress}
                            variant='filled'
                            disabled={success || loading}
                            white
                            noAutofillColor
                            onFocus={() => setTextFieldFocus(true)}
                            onBlur={() => setTextFieldFocus(false)}
                        />
                    </PasswordRequirementsChecker>
                </div>
            </>
        );
    };

    return (
        <Wrapper loading={loading} isMobile={isMobile}>
            <div className={cx(classes.body)}>
                <WelcomeText
                    title='Nytt lösenord'
                    subtitle='Ange ditt nya lösenord nedan.'
                />
                {textFields()}
                {success ? (
                    <div className={classes.successText}>
                        <Typography variant='body1'>
                            Ditt lösenord har ändrats. Gå tillbaka till login
                            via knappen nedan.
                        </Typography>
                    </div>
                ) : null}
                {loginButtons()}
            </div>
        </Wrapper>
    );
}
