import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import TextField, { Label } from 'components/interface/textField/TextField';
import PasswordField from 'components/interface/PasswordField/PasswordField';
import ReactGA from 'react-ga';
import { Typography } from '@mui/material';
import useLogin from './hooks/useLogin';
import { WelcomeText } from '../Wrapper';
import { LoginButton } from './View';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    textFieldBase: {
        width: '100%',
        maxWidth: '350px',
        flexShrink: 0,
        marginTop: theme.spacing(0.5),
    },
    textField: {
        maxWidth: '350px',
    },
    textFieldRoot: {
        height: '56px',
    },
    showPasswordIcon: {
        color: theme.palette.primaryDark.main,
    },
    loginButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'space-between',
        marginTop: theme.spacing(2),
    },
    forgotPasswordButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textDecoration: 'underline',
        color: theme.palette.primaryDark.main,
        userSelect: 'none',
    },
    passwordFieldLabelWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: theme.spacing(0.5),
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: theme.spacing(2),
    },
    label: {
        'color': theme.palette.primaryDark.main,
        '& p': {
            fontWeight: 'bold',
        },
    },
    desktop: {},
}));

interface Props {
    setLoading: (loading: boolean) => void;
    redirectedFrom: string | null;
}

export default function NormalLogin({ setLoading }: Props) {
    const { classes } = useStyles();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const { login, loading } = useLogin();

    React.useEffect(() => {
        setLoading(loading);
    }, [loading]);

    const handleLogin = () => login(username, password);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const loginButtons = () => {
        return (
            <div className={classes.loginButtonWrapper}>
                <LoginButton
                    color='primaryDark'
                    variant='contained'
                    onClick={handleLogin}
                    disableElevation>
                    <Typography variant='body1' color='inherit'>
                        <b>Logga in</b>
                    </Typography>
                </LoginButton>
            </div>
        );
    };
    const textFields = () => {
        return (
            <>
                <div className={classes.inputWrapper}>
                    <div className={classes.label}>
                        <Label text='E-post' inheritColor />
                    </div>
                    <TextField
                        id='username'
                        autoComplete='username'
                        classes={{
                            base: classes.textFieldBase,
                            field: classes.textField,
                        }}
                        InputProps={{
                            classes: { root: classes.textFieldRoot },
                        }}
                        value={username || ''}
                        onChange={setUsername}
                        placeholder='exempel@gmail.com'
                        onKeyPress={handleKeyPress}
                        variant='filled'
                        white
                        autoFocus
                        noAutofillColor
                        fullWidth
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <div className={classes.passwordFieldLabelWrapper}>
                        <div className={classes.label}>
                            <Label text='Lösenord' inheritColor noMargin />
                        </div>
                        <div className={classes.forgotPasswordButtonWrapper}>
                            <NavLink exact to={`/glomt-uppgift`}>
                                <Typography
                                    variant='body2'
                                    color='inherit'
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'Login',
                                            action: 'Go to forgot password',
                                        });
                                    }}>
                                    <b>Glömt ditt lösenord?</b>
                                </Typography>
                            </NavLink>
                        </div>
                    </div>

                    <PasswordField
                        id='password'
                        autoComplete='current-password'
                        classes={{
                            base: classes.textFieldBase,
                            field: classes.textField,
                        }}
                        InputProps={{
                            classes: { root: classes.textFieldRoot },
                        }}
                        iconClassName={classes.showPasswordIcon}
                        value={password || ''}
                        onChange={setPassword}
                        onKeyPress={handleKeyPress}
                        variant='filled'
                        white
                        noAutofillColor
                        fullWidth
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <WelcomeText
                title='Välkommen tillbaka!'
                subtitle='Logga in nedan för att fortsätta.'
            />
            <div>
                {textFields()}
                {loginButtons()}
            </div>
        </>
    );
}
